import {
  ExitToApp,
  Favorite,
  Login,
  // Help,
  Person,
  ShoppingBag,
} from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useFetcher } from "@remix-run/react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useUserId } from "~/hooks";

interface ProfileOptionsProps {
  listItem?: boolean;
}

export const ProfileOptions = ({ listItem }: ProfileOptionsProps) => {
  const fetcher = useFetcher();
  const userId = useUserId();

  const links = [
    <Link key="profile-orders" to="/profile/orders">
      {listItem ? (
        <ListItem>
          <ListItemIcon>
            <ShoppingBag />
          </ListItemIcon>
          <ListItemText>Orders</ListItemText>
        </ListItem>
      ) : (
        <MenuItem>Orders</MenuItem>
      )}
    </Link>,
    <Link key="profile-edit" to="/profile/edit">
      {listItem ? (
        <ListItem>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText>Profile options</ListItemText>
        </ListItem>
      ) : (
        <MenuItem>Profile</MenuItem>
      )}
    </Link>,
    <Link to="/profile/wishlist" key="profile-wishlist">
      {listItem ? (
        <ListItem>
          <ListItemIcon>
            {" "}
            <Favorite />{" "}
          </ListItemIcon>
          <ListItemText>Wishlist</ListItemText>
        </ListItem>
      ) : (
        <MenuItem>Wishlist</MenuItem>
      )}
    </Link>,
    userId &&
      (listItem ? (
        <ListItem
          key="profile-logout"
          onClick={() => {
            fetcher.submit({}, { method: "post", action: "/logout" });
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </ListItem>
      ) : (
        <MenuItem
          key="profile-logout"
          onClick={() => {
            fetcher.submit({}, { method: "post", action: "/logout" });
          }}
        >
          Logout
        </MenuItem>
      )),
    !userId && (
      <Link key="profile-login" to="/login">
        {listItem ? (
          <ListItem>
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </ListItem>
        ) : (
          <MenuItem key="profile-login">Login</MenuItem>
        )}
      </Link>
    ),
  ];

  return (
    <PopupState variant="popover" popupId="profile-popup-menu">
      {(popupState) => (
        <>
          {listItem ? (
            links.map((a) => a)
          ) : (
            <IconButton {...bindTrigger(popupState)}>
              <Person />
            </IconButton>
          )}
          <Menu {...bindMenu(popupState)}>{links.map((a) => a)}</Menu>
        </>
      )}
    </PopupState>
  );
};
