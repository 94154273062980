import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import PopupState from "material-ui-popup-state";
import CartListItem from "./CartListItem";
import { Link } from "@remix-run/react";
import { useMainData } from "~/hooks";
import { ServerOnly } from "remix-utils/server-only";
import { ClientOnly } from "remix-utils/client-only";
import { useMemo } from "react";
import { useCartStore } from "~/store";
import { ShoppingBasket } from "@mui/icons-material";

interface CartProps {
  listItem?: boolean;
}

export default function Cart({ listItem }: CartProps) {
  const [cart, total] = useCartStore((s) => [s.cart, s.total]);
  const { settings } = useMainData();

  const variationCount = useMemo(() => {
    let vCount = 0;
    for (const pId of Object.values(cart)) {
      vCount += Object.keys(pId).length;
    }
    return vCount;
  }, [cart]);
  const hasProducts = Object.keys(cart).length > 0;

  const drawerWidth = "min(320px, 100%)";
  return (
    <PopupState variant="popover" popupId={`cart-popup`}>
      {(popupState) => (
        <>
          <ServerOnly>
            {() => (
              <IconButton>
                <ShoppingBasket />
              </IconButton>
            )}
          </ServerOnly>
          <ClientOnly>
            {() =>
              listItem ? (
                <ListItem onClick={popupState.toggle}>
                  <ListItemIcon>
                    <Badge
                      badgeContent={variationCount}
                      color={hasProducts ? "secondary" : undefined}
                    >
                      <ShoppingBasket />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Cart</ListItemText>
                </ListItem>
              ) : (
                <Badge
                  badgeContent={variationCount}
                  color={hasProducts ? "secondary" : undefined}
                >
                  <IconButton onClick={popupState.toggle}>
                    <ShoppingBasket />
                  </IconButton>
                </Badge>
              )
            }
          </ClientOnly>

          <Drawer
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            anchor="right"
            open={popupState.isOpen}
            onClose={popupState.close}
          >
            <Box
              sx={{
                width: drawerWidth,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 4,
                paddingBottom: 4,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }}></div>
              {hasProducts &&
                Object.keys(cart).map((cartProdId) =>
                  Object.keys(cart[cartProdId]).map((vId) => (
                    <CartListItem
                      key={vId}
                      productId={cartProdId}
                      variationId={vId}
                    />
                  ))
                )}
              {!hasProducts && (
                <Typography sx={{ textAlign: "center", marginBottom: 2 }}>
                  Add some products to your cart and you will see them here.
                </Typography>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {hasProducts && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: (t) => t.spacing(2),
                      }}
                    >
                      <Typography>Subtotal</Typography>
                      <Typography>
                        {total} {settings.defaultCurrency}
                      </Typography>
                    </Box>
                    <Link to="/checkout">
                      <Button style={{ width: "100%" }} variant="contained">
                        Checkout
                      </Button>
                    </Link>
                  </>
                )}

                <Button
                  onClick={popupState.close}
                  variant={hasProducts ? undefined : "contained"}
                >
                  Continue shopping
                </Button>
              </div>
            </Box>
          </Drawer>
        </>
      )}
    </PopupState>
  );
}
